import React from 'react'

import { Col, Row, Input, Select } from 'antd'
import t from 'prop-types'

const { Option } = Select

const Item = ({ item, children, onChangeValue, questionsTypes }) => (
  <>
    <Row gutter={16}>
      <Col span={8}>
        <Input
          defaultValue={item?.section || null}
          onChange={(e) =>
            onChangeValue(item, Number(e.target.value), 'section')
          }
        />
      </Col>
      <Col span={8}>
        <Input
          defaultValue={item?.length || null}
          onChange={(e) =>
            onChangeValue(item, Number(e.target.value), 'length')
          }
        />
      </Col>
      <Col span={8}>
        <Select
          style={{ width: '100%' }}
          optionFilterProp="children"
          placeholder="Selecione o arquivo"
          defaultValue={item?.type || null}
          onChange={(value) => onChangeValue(item, Number(value), 'type')}
        >
          {questionsTypes.map((i) => (
            <Option key={String(i?.key)} value={i?.value}>
              {i?.text}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop: 10 }}>
      <Col span={24}>
        <Input
          defaultValue={item?.question || null}
          onChange={(e) => onChangeValue(item, e.target.value, 'question')}
        />
      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop: 10 }}>
      <Col span={24}>
        <Input
          placeholder="Chave BI"
          defaultValue={item?.question_key || null}
          onChange={(e) => onChangeValue(item, e.target.value, 'question_key')}
        />
      </Col>
    </Row>
    {children}
  </>
)

Item.defaultTypes = {}

Item.propTypes = {
  item: t.objectOf(t.objectOf(t.string)).isRequired,
  children: t.node.isRequired,
  onChangeValue: t.func.isRequired,
  questionsTypes: t.arrayOf(t.objectOf(t.string)),
}

export default Item
