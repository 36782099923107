import React from 'react'

import {
  FormOutlined,
  LinkOutlined,
  CheckOutlined,
  CameraOutlined,
  LogoutOutlined,
  PlayCircleOutlined,
  ShoppingCartOutlined,
  FileDoneOutlined,
  SignatureOutlined,
  PushpinOutlined,
  TruckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import { cond, equals, always } from 'ramda'

const iconConfig = { fontSize: 25, color: 'white' }

const feedIcon = {
  quit: <LogoutOutlined style={iconConfig} color="white" />,
  start: <PlayCircleOutlined style={iconConfig} color="white" />,
  finish: <CheckOutlined style={iconConfig} color="white" />,
  photos: <CameraOutlined style={iconConfig} color="white" />,
  'open-web': <LinkOutlined style={iconConfig} color="white" />,
  'form-sended': <FormOutlined style={iconConfig} color="white" />,
  'send-point-of-sale': (
    <ShoppingCartOutlined style={iconConfig} color="white" />
  ),
  'open-cover-letter': <FileDoneOutlined color="white" style={iconConfig} />,
  signature: <SignatureOutlined color="white" style={iconConfig} />,
  'started-collect': <PushpinOutlined color="white" style={iconConfig} />,
  collected: <PushpinOutlined color="white" style={iconConfig} />,
  'started-delivery': <TruckOutlined color="white" style={iconConfig} />,
  delivered: <TruckOutlined color="white" style={iconConfig} />,
  'no-collected': <CloseCircleOutlined color="white" style={iconConfig} />,
}

export const feedColor = {
  quit: '#F3615F',
  start: '#089EFD',
  finish: '#669275',
  photos: '#0B35FA',
  'open-web': '#C5C5C5',
  'form-sended': '#F1B950',
  'send-point-of-sale': '#6D5ACF',
  'open-cover-letter': 'purple',
  signature: 'black',
  'started-collect': 'green',
  collected: 'green',
  'started-delivery': 'blue',
  delivered: 'blue',
  'no-collected': 'red',
}

const feedLabel = cond([
  [equals('send-point-of-sale'), always('Enviou um Produto')],
  [equals('quit'), always('Desistiu da missão')],
  [equals('start'), always('Iniciou a missão')],
  [equals('finish'), always('Finalizou a missão')],
  [equals('photos'), always('Enviou Foto')],
  [equals('open-web'), always('Anexo Aberto')],
  [equals('form-sended'), always('Enviou Formulário')],
])
const iconColor = cond([
  [equals('quit'), always('#F3615F')],
  [equals('start'), always('#089EFD')],
  [equals('finish'), always('#669275')],
  [equals('photos'), always('#0B35FA')],
  [equals('open-web'), always('#C5C5C5')],
  [equals('form-sended'), always('#F1B950')],
])
const statusRegisterLabel = cond([
  [equals(0), always('Aguardando Avaliação')],
  [equals(1), always('Aprovado')],
  [equals(2), always('Reprovado')],
  [equals(3), always('Em Análise')],
  [equals(4), always('Em Correção')],
])
const statusRegisterColor = cond([
  [equals(0), always('#fbbd08')],
  [equals(1), always('#52c41a')],
  [equals(2), always('#db2828')],
  [equals(3), always('#f2711c')],
  [equals(4), always('#2185d0')],
])
export {
  feedIcon,
  feedLabel,
  iconColor,
  statusRegisterColor,
  statusRegisterLabel,
}
