import React from 'react'

import {
  PlayCircleOutlined,
  CameraOutlined,
  FormOutlined,
  CheckCircleOutlined,
  FileAddFilled,
  FileDoneOutlined,
  SignatureOutlined,
  PushpinOutlined,
  TruckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'

const fontSize = {
  fontSize: '16px',
}

export const actionIcon = {
  start: <PlayCircleOutlined style={fontSize} />,
  photos: <CameraOutlined style={fontSize} />,
  'open-web': <FileAddFilled style={fontSize} />,
  'form-sended': <FormOutlined style={fontSize} />,
  finish: <CheckCircleOutlined style={fontSize} />,
  'open-cover-letter': <FileDoneOutlined style={fontSize} />,
  signature: <SignatureOutlined style={fontSize} />,
  'started-collect': <PushpinOutlined style={fontSize} />,
  collected: <PushpinOutlined style={fontSize} />,
  'started-delivery': <TruckOutlined style={fontSize} />,
  delivered: <TruckOutlined style={fontSize} />,
  'no-collected': <CloseCircleOutlined style={fontSize} />,
}

export const actionLabel = {
  start: 'Iniciou',
  photos: 'Tirou Foto',
  'open-web': 'Abriu Arquivo',
  'form-sended': 'Enviou Respostas',
  'send-point-of-sale': 'Enviou PDV',
  finish: 'Finalizou',
  removed: 'Removido da Missão',
  quit: 'Desistiu',
  'open-cover-letter': 'Abriu Carta de Apresentação',
  signature: 'Assinou Documento',
  'started-collect': 'Iniciou Coleta',
  collected: 'Coletou Item',
  'started-delivery': 'Inicou Entrega',
  delivered: 'Entregou Item',
  'no-collected': 'Item Não Coletado',
}

export const actionColor = {
  start: '#089EFD',
  photos: '#0B35FA',
  'open-web': '#C5C5C5',
  'form-sended': '#F1B950',
  'send-point-of-sale': '#6D5ACF',
  finish: '#669275',
  removed: 'red',
  'open-cover-letter': 'purple',
  signature: 'purple',
  'started-collect': 'blue',
  collected: 'blue',
  'started-delivery': 'green',
  delivered: 'green',
  'no-collected': 'red',
}
