import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

function useMissionsMain() {
  const [missionsMain, setMissionsMain] = useState([])
  const [collectData, setCollectData] = useState([])
  const [deliveryPlacesData, setDeliveryPlacesData] = useState([])
  const [hasDelivery, setHasDelivery] = useState(false)

  const getMissionsMain = useCallback(async () => {
    try {
      const res = await api.get('missions-main/customers', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      res.data.forEach((item) => {
        if (item.has_collect || item.has_delivery_places) {
          setHasDelivery(true)
        }
      })

      return setMissionsMain(
        res.data.map((item) => ({
          label: item.title,
          value: item.uid,
        }))
      )
    } catch (error) {
      return message.error(error.message)
    }
  }, [])

  const getDeliveryData = useCallback(async (value) => {
    try {
      const res = await api.get('missions-main/customers/delivery', {
        params: {
          missionsMainId: value,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCollectData(
        res.data.collectData.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      )
      return setDeliveryPlacesData(
        res.data.deliveryPlacesData.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      )
    } catch (error) {
      return message.error(error.message)
    }
  }, [])

  return {
    getMissionsMain,
    missionsMain,
    getDeliveryData,
    collectData,
    deliveryPlacesData,
    setCollectData,
    setDeliveryPlacesData,
    hasDelivery,
  }
}

export { useMissionsMain }
