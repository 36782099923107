import React, { useState, useEffect } from 'react'

import { Button, Form, DatePicker, Select } from 'antd'
import t from 'prop-types'

import useForms from '~/hooks/useForms'
// import { useMissionsMain } from '~/hooks/useMissionsMain'
import useReports from '~/hooks/useReports'

import { TYPE_EXPORTATION } from '../../helpers'

const { Option } = Select
const types = [
  {
    key: 0,
    text: '.XLS',
    value: TYPE_EXPORTATION.XLS,
  },
  {
    key: 1,
    text: 'Imagens(Formulário)',
    value: TYPE_EXPORTATION.ZIP_IMAGES,
  },
  {
    key: 2,
    text: 'Imagens(Fotos)',
    value: TYPE_EXPORTATION.ZIP_PHOTOS,
  },
]

const ExportForm = ({ loadNewRegisters, customerId }) => {
  const [filters, setFilters] = useState({
    type: '',
    final: '',
    initial: '',
    formsId: undefined,
    missionsMainId: undefined,
  })

  const { getAll, loadingForms, forms } = useForms()
  const {
    onGeneratePhotosZipped,
    onGenerateReports,
    onGenerateImagesZipped,
    loadingGenerate,
    loadingGenerateZip,
  } = useReports()

  // const { getMissionsMain, missionsMain } = useMissionsMain()
  const [hasOneSelection, setHasOneSelection] = useState('')

  const submit = async (filtersToExportation) => {
    if (filtersToExportation.type === TYPE_EXPORTATION.XLS) {
      await onGenerateReports(filtersToExportation)
    } else if (filtersToExportation.type === TYPE_EXPORTATION.ZIP_IMAGES) {
      await onGenerateImagesZipped({ ...filtersToExportation, customerId })
    } else if (filtersToExportation.type === TYPE_EXPORTATION.ZIP_PHOTOS) {
      await onGeneratePhotosZipped({ ...filtersToExportation, customerId })
    }

    const page = 1
    await loadNewRegisters(page)
  }

  const hasTypeZipPhotosSelected = () => {
    return filters.type === TYPE_EXPORTATION.ZIP_PHOTOS
  }

  const onChangeInputValue = (key, value) => {
    if (!value) {
      setHasOneSelection('')
    } else {
      setHasOneSelection(key)
    }

    setFilters((prevState) => ({
      ...prevState,
      [key]: value || undefined,
    }))
  }

  useEffect(() => {
    getAll({ customerId })
    // getMissionsMain()
  }, [customerId]) //eslint-disable-line

  return (
    <Form>
      <Form.Item>
        <DatePicker
          value={filters.initial}
          format="DD/MM/YYYY"
          placeholder="Data Inicial"
          onChange={(initial) => onChangeInputValue('initial', initial)}
        />
      </Form.Item>
      <Form.Item>
        <DatePicker
          value={filters.final}
          format="DD/MM/YYYY"
          placeholder="Data Final"
          onChange={(final) => onChangeInputValue('final', final)}
        />
      </Form.Item>
      <Form.Item>
        <Select
          optionFilterProp="children"
          placeholder="Selecione o arquivo"
          onChange={(type) => onChangeInputValue('type', type)}
        >
          {types.map((i) => (
            <Option key={i.key} value={i.value}>
              {i.text}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {!hasTypeZipPhotosSelected() && (
        <Form.Item>
          <Select
            disabled={!filters.type || hasOneSelection === 'missionsMainId'}
            value={filters.formsId}
            loading={loadingForms}
            optionFilterProp="children"
            placeholder="Selecione o formulário"
            onChange={(value) => onChangeInputValue('formsId', value)}
            allowClear
          >
            {forms.map((i) => (
              <Option key={i.uid} value={i.uid}>
                {i.id} - {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {/* <Form.Item>
        <Select
          disabled={
            !filters.type ||
            hasOneSelection === 'formsId' ||
            filters.type !== 'xls'
          }
          placeholder="Selecione a Subcategoria"
          value={filters.missionsMainId}
          loading={loadingForms}
          optionFilterProp="children"
          onChange={(value) => onChangeInputValue('missionsMainId', value)}
          options={missionsMain}
          allowClear
        />
      </Form.Item> */}

      <Form.Item>
        <Button
          shape="round"
          type="primary"
          loading={loadingGenerate || loadingGenerateZip}
          disabled={loadingGenerate || loadingGenerateZip}
          onClick={() => submit(filters)}
        >
          Aplicar
        </Button>
      </Form.Item>
    </Form>
  )
}

ExportForm.propTypes = {
  loadNewRegisters: t.func,
  customerId: t.string,
}

export default ExportForm
