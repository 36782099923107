import React, { useEffect } from 'react'

import { ReloadOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Drawer, Dropdown, Tooltip } from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'

import Table from '~/components/organisms/Table'
import useRegisters from '~/hooks/useRegisters'

import {
  APPROVATION_BATCH_STATUS,
  INIT_PAGE,
  QUEUE_APPROVATION_STATUS,
} from '../helpers'

const ITEMS_PER_PAGE = 10

export function ApprovationsBatch({ onClose, open }) {
  const {
    loadingRegister,
    getQueueApprovationsBatch,
    reprocessApprovationRegistersInBatchById,
    paginationAprove: pagination,
    queueApprovationsBatch,
  } = useRegisters()

  const status = [
    QUEUE_APPROVATION_STATUS.QUEUED,
    QUEUE_APPROVATION_STATUS.PROCESSING,
    QUEUE_APPROVATION_STATUS.DONE,
  ]
  const COLUMNS = [
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      render: (total, data) => {
        const isShowAlert = data.motivation_error && data.status !== 'DONE'

        return (
          <>
            {isShowAlert && (
              <Tooltip placement="left" title={data.motivation_error}>
                <WarningOutlined style={{ color: 'red' }} />
              </Tooltip>
            )}
            &nbsp;
            {total}
          </>
        )
      },
    },
    {
      title: 'Aprovados',
      key: 'total_processed',
      dataIndex: 'total_processed',
    },
    {
      title: 'Situação',
      key: 'status',
      dataIndex: 'status',
      render: (rowStatus) => APPROVATION_BATCH_STATUS[rowStatus],
    },
    {
      title: 'Criado em',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (createdAt) => dayjs(createdAt).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Atualizar em',
      key: 'updated_at',
      dataIndex: 'updated_at',
      render: (updatedAt) => dayjs(updatedAt).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: '',
      key: '',
      render(_, data) {
        if (!data.is_need_reprocess) {
          return false
        }

        return (
          <Dropdown.Button
            menu={{
              onClick: () => {
                reprocessApprovationRegistersInBatchById(data.id, () =>
                  getQueueApprovationsBatch(status, INIT_PAGE, ITEMS_PER_PAGE)
                )
              },
              items: [
                {
                  label: 'Reprocessar',
                  key: '1',
                },
              ],
            }}
          >
            Ações
          </Dropdown.Button>
        )
      },
    },
  ]

  useEffect(() => {
    const sub = getQueueApprovationsBatch(status, INIT_PAGE, ITEMS_PER_PAGE)

    return () => sub
  }, []) //eslint-disable-line

  return (
    <Drawer
      title="Fila de Aprovações"
      placement="right"
      closable={false}
      onClose={onClose}
      width={740}
      open={open}
      key="approvation-batch"
    >
      <Button
        type="primary"
        style={{ float: 'right' }}
        onClick={() =>
          getQueueApprovationsBatch(status, INIT_PAGE, ITEMS_PER_PAGE)
        }
      >
        <ReloadOutlined />
      </Button>
      <Table
        key="table"
        size="middle"
        data={queueApprovationsBatch}
        columns={COLUMNS}
        total={pagination.total}
        loading={loadingRegister}
        current={pagination.current}
        pageSize={pagination.pageSize}
        disableOptionChangeItemsPerPage
        handlePagination={(page) => {
          return getQueueApprovationsBatch(status, page, ITEMS_PER_PAGE)
        }}
      />
    </Drawer>
  )
}

ApprovationsBatch.propTypes = {
  onClose: t.func,
  open: t.func,
}
