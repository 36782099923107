import React from 'react'
import ReactMapGl from 'react-map-gl'

import t from 'prop-types'

import { MAPBOX_TOKEN } from '~/config/env'

import { Marker } from './Marker'

export function Maps({ viewport, items }) {
  return (
    <ReactMapGl
      {...viewport}
      width="100%"
      height={300}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      mapStyle="mapbox://styles/missionbrasil/cjkmphdta3c5r2rpezblnlfth"
    >
      {items?.map((item, index) => (
        <Marker key={`item-${Number(index)}`} {...item} index={index} />
      ))}
    </ReactMapGl>
  )
}

Maps.propTypes = {
  viewport: t.objectOf({
    latitude: t.number,
    longitude: t.number,
  }),
  items: t.arrayOf(
    t.objectOf({
      index: t.number,
      lat: t.number,
      lng: t.number,
      action: t.string,
    })
  ),
}
