/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import Carousel, { Modal, ModalGateway } from 'react-images'

import { Card } from 'antd'

export function DeliveryData({ title, data, fields, location }) {
  const [lightBox, setLightBox] = useState(false)
  const [photos, setPhotos] = useState([])

  function onOpenPhotos(items) {
    setPhotos(items)
    setLightBox(true)
  }

  return (
    <>
      <Card title={title}>
        <h3 className="font-semibold">Local</h3>
        <p>{location}</p>
        <p>
          {fields?.map((dp) => {
            const answer = data?.find((i) => i.id === dp?.id)
            const isImage =
              Array.isArray(answer?.value) &&
              answer?.value.every((item) => item?.hasOwnProperty('path'))
            const isArray = Array.isArray(answer?.value) && !isImage
            const isString = typeof answer?.value === 'string'

            return (
              <div className="mb-4" key={dp?.id}>
                <h3 className="font-semibold">{dp?.name}</h3>
                <p>
                  {isImage && Array.isArray(answer?.value) ? (
                    <div
                      style={{ display: 'flex', flexDirection: 'row', gap: 10 }}
                    >
                      {answer?.value?.map((item) => {
                        return (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <img
                            src={item?.path}
                            key={item?.path}
                            style={{ height: 200, borderRadius: 10 }}
                            className="rounded-sm"
                            onClick={() => onOpenPhotos(answer.value)}
                            alt="del_img"
                          />
                        )
                      })}
                    </div>
                  ) : null}
                  {isArray ? (
                    <span style={{ fontSize: 14 }}>
                      {answer?.value.join(',')}
                    </span>
                  ) : null}
                  {isString ? (
                    <span style={{ fontSize: 14 }}>{answer?.value}</span>
                  ) : null}
                </p>
              </div>
            )
          })}
        </p>
      </Card>
      <ModalGateway>
        {lightBox ? (
          <Modal onClose={() => setLightBox(false)}>
            <Carousel views={photos.map((i) => ({ ...i, source: i.path }))} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
