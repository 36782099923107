import React, { useState } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Col, Space, Row, Input, Button, message } from 'antd'
import t from 'prop-types'

export default function SelectItem({
  items,
  removeItem,
  onChangeSelectItem,
  hasScoreRating,
}) {
  const initialSelectValue = { title: '', score_rating: null }
  const [selectValue, setSelectValue] = useState(initialSelectValue)

  function handleOnPressEnter() {
    if (hasScoreRating) {
      if (selectValue.score_rating === null) {
        return message.error('A pontuação da classificação é obrigatória')
      }
    }
    onChangeSelectItem(selectValue)
    return setSelectValue(initialSelectValue)
  }

  function handleOnChange(key, value) {
    setSelectValue((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  return (
    <Space direction="vertical">
      <Row gutter={16} title="Adicionar itens">
        <Col span={24}>
          <Input
            onPressEnter={(event) => handleOnPressEnter(event)}
            placeholder={`Adicionar respostas...${
              !hasScoreRating ? '(Pressione ENTER para Salvar)' : ''
            }`}
            onChange={({ target }) => handleOnChange('title', target.value)}
            value={selectValue.title}
          />
          {hasScoreRating && (
            <Input
              type="number"
              onPressEnter={(event) => handleOnPressEnter(event)}
              placeholder="Adicionar Pontuação da Classificação (Pressione ENTER para Salvar)"
              onChange={({ target }) =>
                handleOnChange('score_rating', target.value)
              }
              value={selectValue.score_rating}
            />
          )}
        </Col>
      </Row>
      {items.map((item, index) => (
        <Row gutter={16} key={Number(index)}>
          <Col span={16}>
            <Input value={item.title} />
          </Col>
          {item.score_rating && (
            <Col span={4}>
              <Input value={item.score_rating} />
            </Col>
          )}

          <Col span={4}>
            <Button
              block
              shape="round"
              icon={<DeleteOutlined />}
              onClick={() => removeItem(index)}
            />
          </Col>
        </Row>
      ))}
    </Space>
  )
}

SelectItem.propTypes = {
  items: t.arrayOf(t.objectOf(t.any)).isRequired,
  removeItem: t.func.isRequired,
  onChangeSelectItem: t.func.isRequired,
  hasScoreRating: t.bool,
}
