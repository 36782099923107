/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'

import {
  AreaChartOutlined,
  ArrowsAltOutlined,
  EditFilled,
  InfoCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  List,
  Popover,
  Radio,
  Select,
  Space,
  message,
} from 'antd'
import t from 'prop-types'
import { v4 as uuid } from 'uuid'

import useForms from '~/hooks/useForms'
import useFormsItems from '~/hooks/useFormsItems'
import { useMissionsMain } from '~/hooks/useMissionsMain'
import api from '~/services/api'

import { chartsTypes, typeValues } from './constants/types-of-charts'

const LIMIT_CHARTS = 5

export function CreateChart({ onRefresh, open, onClose }) {
  const [form] = Form.useForm()
  const [charts, setCharts] = useState([])
  const [loading, setLoading] = useState(false)
  const [chartType, setChartType] = useState(null)
  const [isDelivery, setIsDelivery] = useState(false)
  const [typeDeliveryFeature, setTypeDeliveryFeature] = useState('')
  const [valueDeliveryFeature, setValueDeliveryFeature] = useState('')
  const [feedsAction, setFeedsAction] = useState([])

  const { getAll, loadingForms, forms } = useForms()
  const { getFormsItems, formsItems, loadingFormsItems } = useFormsItems()
  const {
    collectData,
    deliveryPlacesData,
    getMissionsMain,
    missionsMain,
    getDeliveryData,
    setCollectData,
    setDeliveryPlacesData,
    hasDelivery,
  } = useMissionsMain()

  const deliveryFeedOptions = [
    { label: 'Coletado', value: 'collected' },
    { label: 'Não Coletado', value: 'no-collected' },
    { label: 'Entregue', value: 'delivered' },
  ]

  function resetDefaultDeliveryFeature() {
    setIsDelivery(false)
    setTypeDeliveryFeature('')
    setValueDeliveryFeature('')
    setCollectData([])
    setDeliveryPlacesData([])
    setFeedsAction([])
  }

  async function onIncrementChart(values) {
    if (charts.length >= LIMIT_CHARTS) {
      return message.warning('Por favor, crie no máximo 5 gráficos de uma vez')
    }

    if (isDelivery) {
      if (feedsAction.length > 0 && feedsAction.length < 2) {
        return message.warning(
          'Por favor, selecione duas ou mais opções de feeds'
        )
      }

      if (typeDeliveryFeature.length === 0) {
        return message.warning('Por favor, selecione alguma fonte de dados')
      }
    }

    setCharts((prev) => [
      ...prev,
      {
        uid: uuid(),
        order: charts.length + 1,
        data: {
          ...values,
          type: typeValues[values?.type]?.type,
          entity: isDelivery
            ? typeDeliveryFeature
            : typeValues[values?.type]?.entity,
        },
        ...(valueDeliveryFeature.length > 0 && { valueDeliveryFeature }),
        ...(feedsAction.length > 0 && { feedsAction: feedsAction.join(',') }),
      },
    ])

    resetDefaultDeliveryFeature()
    setChartType(null)
    return form.resetFields()
  }

  async function onSubmit() {
    setLoading(true)

    const res = await api.post(
      'analytics-data',
      { charts },
      {
        validateStatus() {
          return true
        },
      }
    )

    if (res.status !== 201) {
      setLoading(false)
      return message.warning('Houve um erro ao encaminhar')
    }

    form.resetFields()
    message.success('Gráfico criado com sucesso!')
    setCharts([])
    setLoading(false)
    onRefresh()
    return onClose()
  }

  function onChangeForm(id) {
    getFormsItems({ formId: id, offset: 0, limit: 1000 })
  }

  function onChangeType(value) {
    if (!['bar_avg', 'barra', 'doughnut'].includes(value)) {
      setIsDelivery(false)
    }

    setChartType(value)
  }

  function handleOnChangeIsDelivery(value) {
    setIsDelivery(value === 'missionsMain')
  }

  function handleOnChangeSubcategory(value) {
    getDeliveryData(value)
  }

  function handleOnChangeDeliveryQuestions(type, value) {
    setValueDeliveryFeature(value)

    if (type === 'missions_feeds') {
      const { target } = value

      if (target.checked) {
        setFeedsAction((prevState) => [...prevState, target.value])
      } else {
        setFeedsAction((prevState) => {
          if (prevState.length === 1) {
            setTypeDeliveryFeature('')
          }

          return prevState.filter((item) => item !== target.value)
        })
      }
    }

    if (!value) {
      return setTypeDeliveryFeature('')
    }

    return setTypeDeliveryFeature(type)
  }

  useEffect(() => {
    getAll()
    getMissionsMain()
  }, []) //eslint-disable-line

  const hasForm = ![
    'missions_approved',
    'missions_approved_by_group',
    null,
  ].includes(chartType)

  return (
    <Drawer
      open={open}
      width={600}
      destroyOnClose
      onClose={onClose}
      footer={
        charts.length > 0 ? (
          <Button type="primary" onClick={onSubmit} loading={loading}>
            Enviar Gráficos
          </Button>
        ) : null
      }
    >
      <Form layout="vertical" onFinish={onIncrementChart} form={form}>
        <Form.Item
          label="Tipo de Gráfico"
          name="type"
          rules={[{ required: true, message: 'Escolha um Gráfico' }]}
        >
          <Select
            options={chartsTypes}
            suffixIcon={<AreaChartOutlined />}
            onChange={(val) => onChangeType(val)}
          />
        </Form.Item>
        {hasDelivery &&
          ['bar_avg', 'barra', 'doughnut'].includes(chartType) && (
            <Form.Item>
              <Radio.Group
                onChange={(e) => {
                  handleOnChangeIsDelivery(e.target.value)
                  form.resetFields([
                    'missionsMain',
                    'form',
                    'items',
                    'title',
                    'size',
                  ])
                  setTypeDeliveryFeature('')
                }}
                defaultValue="forms"
              >
                <Space direction="horizontal">
                  {[
                    { value: 'forms', label: 'Formulário' },
                    { value: 'missionsMain', label: 'Coleta / Entrega' },
                  ].map((item) => (
                    <Radio value={item.value} key={item.value}>
                      {item.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
        {isDelivery && (
          <Form.Item
            name="missionsMain"
            label="Selecionar a Subcategoria"
            rules={[{ required: true, message: 'Selecione uma Subcategoria' }]}
          >
            <Select
              suffixIcon={<EditFilled />}
              onChange={(val) => handleOnChangeSubcategory(val)}
              options={missionsMain}
            />
          </Form.Item>
        )}

        {isDelivery &&
        (collectData.length > 0 || deliveryPlacesData.length > 0) ? (
          <Form.Item
            label={
              <Space direction="horizontal">
                Selecione as Ações
                <Popover content="É obrigatório selecionar, no mínimo, duas opções para fonte de dados">
                  <InfoCircleOutlined />
                </Popover>
              </Space>
            }
          >
            <Checkbox.Group>
              {deliveryFeedOptions.map((item) => (
                <Checkbox
                  value={item.value}
                  onChange={(val) =>
                    handleOnChangeDeliveryQuestions('missions_feeds', val)
                  }
                  disabled={[
                    'missions_users_delivery',
                    'missions_users_collect',
                    'observation',
                  ].includes(typeDeliveryFeature)}
                >
                  {item.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        ) : null}

        {isDelivery && collectData.length > 0 ? (
          <>
            <Form.Item label="Justificativas de Não Coleta">
              <Select
                suffixIcon={<EditFilled />}
                onChange={(val) =>
                  handleOnChangeDeliveryQuestions('observation', val)
                }
                options={[
                  {
                    value: 'observation',
                    label: 'Não Conseguiu Realizar Coleta',
                  },
                ]}
                disabled={[
                  'missions_users_delivery',
                  'missions_users_collect',
                  'missions_feeds',
                ].includes(typeDeliveryFeature)}
                allowClear
              />
            </Form.Item>

            <Form.Item label="Pergunta de Coleta">
              <Select
                suffixIcon={<EditFilled />}
                onChange={(val) =>
                  handleOnChangeDeliveryQuestions('missions_users_collect', val)
                }
                options={collectData}
                disabled={[
                  'missions_users_delivery',
                  'missions_feeds',
                  'observation',
                ].includes(typeDeliveryFeature)}
                allowClear
              />
            </Form.Item>
          </>
        ) : null}
        {isDelivery && deliveryPlacesData.length > 0 ? (
          <Form.Item label="Pergunta de Entrega">
            <Select
              suffixIcon={<EditFilled />}
              onChange={(val) =>
                handleOnChangeDeliveryQuestions('missions_users_delivery', val)
              }
              options={deliveryPlacesData}
              disabled={[
                'missions_users_collect',
                'missions_feeds',
                'observation',
              ].includes(typeDeliveryFeature)}
              allowClear
            />
          </Form.Item>
        ) : null}

        {hasForm && !isDelivery ? (
          <Form.Item
            name="form"
            label="Escolher Formulário"
            rules={[{ required: true, message: 'Selecione um Formulário' }]}
          >
            <Select
              loading={loadingForms}
              suffixIcon={<EditFilled />}
              onChange={(val) => onChangeForm(val)}
              options={forms?.map((i) => ({ label: i.name, value: i.uid }))}
            />
          </Form.Item>
        ) : null}
        {hasForm && formsItems.length > 0 && !isDelivery ? (
          <Form.Item
            label="Adicione Perguntas"
            name="items"
            rules={[
              {
                required: hasForm,
                message: 'Adicione pelo menos uma pergunta',
              },
            ]}
          >
            <Select
              mode="multiple"
              loading={loadingFormsItems}
              placeholder="Add Perguntas"
              options={formsItems.map((item) => ({
                value: item.uid,
                label: `${item.order} - ${item.question}`,
              }))}
            />
          </Form.Item>
        ) : null}
        <Form.Item
          label="Título"
          name="title"
          rules={[{ required: true, message: 'Adicione um Título' }]}
        >
          <Input placeholder="Título" />
        </Form.Item>
        <Form.Item
          label="Tamanho"
          name="size"
          rules={[{ required: true, message: 'Adicione o tamanho do Card' }]}
        >
          <Select
            title="Tipo"
            suffixIcon={<ArrowsAltOutlined />}
            options={[
              { value: 'full', label: '100%' },
              { value: 'half', label: '50%' },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Adicionar
          </Button>
        </Form.Item>
      </Form>
      <List>
        {charts?.map((i) => (
          <List.Item key={i.uid}>
            <List.Item.Meta title={i.data?.title} />
            <div>
              <Button
                type="dashed"
                onClick={() => setCharts(charts.filter((c) => c !== i))}
              >
                Remover
              </Button>
            </div>
          </List.Item>
        ))}
      </List>
    </Drawer>
  )
}

CreateChart.propTypes = {
  onClose: t.func,
  onRefresh: t.func,
  open: t.bool,
}
