import React from 'react'
import { Popup, Marker as MarkerGl } from 'react-map-gl'

import { PushpinOutlined, TruckOutlined } from '@ant-design/icons'
import t from 'prop-types'

const markerStyles = {
  width: 50,
  height: 50,
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  borderEndEndRadius: 10,
  borderTopLeftRadius: 25,
  borderTopRightRadius: 25,
  borderBottomLeftRadius: 25,
}

export function Marker({ index, lat, lng, action }) {
  const icon = {
    'started-collect': (
      <PushpinOutlined style={{ color: 'white', fontSize: 15 }} />
    ),
    'no-collected': (
      <PushpinOutlined style={{ color: 'white', fontSize: 15 }} />
    ),
    delivered: <TruckOutlined style={{ color: 'white', fontSize: 15 }} />,
  }
  const iconBg = {
    'no-collected': 'red',
    'started-collect': 'blue',
    delivered: 'green',
  }
  const title = {
    'no-collected': 'Item Não Coletado',
    'started-collect': 'Item Coletado',
    delivered: 'Item Entregue',
  }

  return (
    <div key={`key-${index}`}>
      <Popup
        anchor="top"
        tipSize={10}
        offsetTop={30}
        offsetLeft={15}
        closeButton={false}
        latitude={lat}
        longitude={lng}
      >
        <div style={{ fontSize: 13, textAlign: 'center' }}>
          <h5>{title[action]}</h5>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/?q=${lat}, ${lng}`}
            >
              {`(${lat}, ${lng})`}
            </a>
          </p>
        </div>
      </Popup>
      <MarkerGl
        key={`key-${index}`}
        offsetTop={0}
        offsetLeft={0}
        latitude={lat}
        longitude={lng}
      >
        <div style={{ backgroundColor: iconBg[action], ...markerStyles }}>
          {icon[action]}
        </div>
      </MarkerGl>
    </div>
  )
}

Marker.propTypes = {
  index: t.number,
  lat: t.number,
  lng: t.number,
  action: t.string,
}
