import Carousel, { Modal, ModalGateway } from 'react-images'

import t from 'prop-types'
import * as d3 from 'd3'

import React, { useEffect, useState } from 'react'
import { FlyToInterpolator } from 'react-map-gl'

import { Card } from 'antd'

import Loading from '~/components/atoms/Loading'
import api from '~/services/api'

import { DeliveryData } from './components/DeliveryData'
import { Maps } from './components/Maps'

const position = {
  latitude: -8.269214,
  longitude: -55.441382,
}

const animationViewport = {
  zoom: 9,
  transitionDuration: 5000,
  transitionEasing: d3.easeCubic,
  transitionInterpolator: new FlyToInterpolator(),
}

export function Delivery({ id }) {
  const [lightBox, setLightBox] = useState(false)
  const [photos, setPhotos] = useState([])
  const [viewport, setViewport] = useState({
    zoom: 0,
    height: 300,
    ...position,
    ...animationViewport,
    width: window.innerWidth,
  })
  const [loading, setLoading] = useState(true)
  const [deliveryData, setDeliveryData] = useState(null)

  async function getData() {
    setLoading(true)
    const res = await api.get(`/delivery/registers/${id}`)
    const result = res?.data
    setDeliveryData(result)

    setViewport((prev) => ({
      ...prev,
      latitude: result?.collectFeed[0]?.lat,
      longitude: result?.collectFeed[0]?.lng,
    }))

    setLoading(false)
  }

  function onOpenPhotos(items) {
    setPhotos(items)
    setLightBox(true)
  }

  useEffect(() => {
    getData()
  }, []) //eslint-disable-line

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Maps
          viewport={viewport}
          current={deliveryData?.collectFeed[0]}
          items={[
            {
              ...deliveryData?.collectFeed[0],
            },
            {
              id: 2,
              action: 'delivered',
              lat: Number(deliveryData?.result?.delivery_lat),
              lng: Number(deliveryData?.result?.delivery_lng),
            },
          ]}
        />
        {deliveryData?.result?.observation !== null ? (
          <Card title="Não Coletado">{deliveryData?.result?.observation}</Card>
        ) : null}
        {deliveryData?.result?.unproductive_data !== null ? (
          <Card title="Não Coletado">
            <h3 className="font-semibold">Opção</h3>
            <p>{deliveryData?.result?.unproductive_data?.observation}</p>
            <h3 className="font-semibold">Detalhes</h3>
            <p>
              {deliveryData?.result?.unproductive_data?.observation_description}
            </p>
            <h3 className="font-semibold">Fotos</h3>
            <p>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                {deliveryData?.result?.unproductive_data?.observation_image_paths?.map(
                  (item) => {
                    return (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                      <img
                        src={item?.path}
                        key={item?.path}
                        style={{ height: 200, borderRadius: 10 }}
                        className="rounded-sm"
                        onClick={() =>
                          onOpenPhotos(
                            deliveryData?.result?.unproductive_data
                              ?.observation_image_paths
                          )
                        }
                        alt="del_img"
                      />
                    )
                  }
                )}
              </div>
            </p>
            ) : null}
          </Card>
        ) : null}

        {deliveryData?.result?.observation === null &&
        deliveryData?.result?.unproductive_data === null ? (
          <>
            <DeliveryData
              location="Local da Missão"
              title="Coleta"
              data={deliveryData?.result?.collect_data?.fields}
              fields={deliveryData?.result?.collect_fields?.fields}
            />
            <DeliveryData
              location={deliveryData?.result?.delivery_address}
              title={`Entrega em ${deliveryData?.result?.delivery_name}`}
              data={deliveryData?.result?.delivery_data?.fields}
              fields={deliveryData?.result?.delivery_places_data?.fields}
            />
          </>
        ) : null}
      </div>
      <ModalGateway>
        {lightBox ? (
          <Modal onClose={() => setLightBox(false)}>
            <Carousel views={photos.map((i) => ({ ...i, source: i.path }))} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

Delivery.propTypes = {
  id: t.string,
}
