/* eslint-disable no-nested-ternary */
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import t from 'prop-types'

import { backgroundColors } from '../Colors'
import { Container } from './styles'

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

export function DoughnutAvg({ data }) {
  const dataItems = Object.keys(data).map((i) => data[i])

  const dataOptions = {
    labels: dataItems.map((i) => i.answer),
    datasets: [
      {
        data: dataItems.map((i) => i.percent),
        backgroundColor: dataItems?.map((_, k) => backgroundColors[k]),
        datalabels: {
          color: 'white',
          labels: {
            title: {
              font: {
                size: 12,
                weight: 600,
              },
            },
          },
          formatter(value) {
            return `${value}%`
          },
        },
      },
    ],
  }

  function getTitles() {
    const titles = {}

    dataItems.forEach((item) => {
      const title = item.question_key || item.question || ''
      if (!titles[title]) {
        titles[title] = 1
      }
    })

    return Object.keys(titles)
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      labels: {
        render: 'label',
      },
      datalabels: {
        display: true,
        color: 'white',
      },
      title: {
        padding: '20',
        display: true,
        text: getTitles(),
      },
    },
  }

  return (
    <Container>
      <Doughnut options={options} data={dataOptions} />
    </Container>
  )
}

DoughnutAvg.propTypes = {
  data: t.objectOf({}),
}
