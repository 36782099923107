import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'
import { CORRECTION } from '~/utils/statusOptions'

function useRegisters() {
  const [loadingMissions, setLoading] = useState(false)
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [missions, setMissions] = useState([])
  const [register, setRegister] = useState(null)
  const [registersCount, setRegistersCount] = useState(null)
  const [paginationAprove, setPaginationAprove] = useState({
    total: 0,
    current: 0,
    pageSize: 0,
  })
  const [queueApprovationsBatch, setQueueApprovationsBatch] = useState([])
  const [registersApprovationBatch, setRegistersApprovationBatch] = useState([])
  const [loadingExportRegistersList, setLoadingExportRegistersList] =
    useState(false)
  const [registersExportList, setRegistersExportList] = useState([])
  const [totalRegistersExportList, setTotalRegistersExportList] = useState([])

  async function getRegistersApprovationBatch(id, page) {
    setLoadingRegister(true)
    try {
      const res = await api.get(
        `/registers/approvation-registers-batch/${id}/registers`,
        {
          params: {
            page,
            itemsPerPage: 10,
          },
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setRegistersApprovationBatch(res.data.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingRegister(false)
    }
  }

  async function getQueueApprovationBatchById(id, index) {
    try {
      const res = await api.get(`registers/approvation-registers-batch/${id}`)
      const registers = queueApprovationsBatch

      registers[index] = {
        ...registers[index],
        ...res.data,
      }

      return setQueueApprovationsBatch([...registers])
    } catch (error) {
      return message.error(error.message)
    }
  }

  async function getQueueApprovationsBatch(status, page, itemsPerPage) {
    try {
      setLoadingRegister(true)
      const res = await api.get(
        `registers/approvation-registers-batch?status=${status.join(
          ','
        )}&page=${page}&itemsPerPage=${itemsPerPage}`
      )
      setPaginationAprove({
        total: res.data.total,
        current: page,
        pageSize: itemsPerPage,
      })
      setQueueApprovationsBatch(res.data.data)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoadingRegister(false)
    }
  }

  const getRegisterById = useCallback(async (id) => {
    try {
      setLoadingRegister(true)
      const res = await api.get(`registers/customers/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setRegister(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingRegister(false)
    }
  }, [])

  const getMissions = useCallback(async (params) => {
    setLoading(true)
    try {
      const res = await api.get('registers/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const total = Number(res.data.count)

      if (total > 0) {
        getRegisterById(res.data.registers[0].uid)
      }

      setRegistersCount(total)
      return setMissions(
        res.data.registers.map((i) => ({ ...i, key: i.mu_key }))
      )
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }, []) //eslint-disable-line

  async function onSubmitRegister(data) {
    try {
      setLoadingRegister(true)
      const res = await api.put('registers/customers', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      if (data.status === CORRECTION) {
        return message.success('Missão reprovada com sucesso')
      }

      return message.success('Missão aprovada com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingRegister(false)
    }
  }

  async function onSubmitApprovals(data) {
    try {
      if (data) {
        data.has_approved = data.has_approved || ""
      }

      setLoadingRegister(true)
      const res = await api.post(
        'registers/approvation-registers-batch',
        data,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const registers = queueApprovationsBatch
      res.data.total_processed = 0
      registers.push(res.data)

      setQueueApprovationsBatch(registers)

      if (data?.isOnly1Mission) {
        return message.success(
          `O registro foi colocado na fila para aprovação, é logo será aprovado.`
        )
      }

      return message.success(
        `O registros foram colocados na fila para aprovação em massa, dentro de alguns minutos eles serão aprovados.`
      )
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingRegister(false)
    }
  }

  async function reprocessApprovationRegistersInBatchById(id, executeAfter) {
    try {
      await api.put(`registers/reprocess-approvation-registers-batch/${id}`)
      message.success(`O registros foram colocados na fila para aprovação em massa, dentro de alguns minutos eles serão aprovados.`)
      await executeAfter()
    } catch(error) {
      return message.error(error.response.data.message   || "Oops! Algum erro inesperado aconteceu.")
    }
  }

  async function submitExport(params) {
    setLoading(true)

    try {
      const res = await api.get('registers/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Solicitação de exportação enviada com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function getExportRegisters(params) {
    try {
      setLoadingExportRegistersList(true)
      const res = await api.get(`registers/customers/export-list`, {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setTotalRegistersExportList(Number(res.data.count))
      return setRegistersExportList(res.data.registers)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingExportRegistersList(false)
    }
  }

  return {
    register,
    missions,
    getMissions,
    registersCount,
    onSubmitRegister,
    getRegisterById,
    loadingRegister,
    loadingMissions,
    onSubmitApprovals,
    paginationAprove,
    getQueueApprovationsBatch,
    getQueueApprovationBatchById,
    queueApprovationsBatch,
    setQueueApprovationsBatch,
    getRegistersApprovationBatch,
    registersApprovationBatch,
    reprocessApprovationRegistersInBatchById,
    submitExport,
    getExportRegisters,
    loadingExportRegistersList,
    registersExportList,
    totalRegistersExportList,
  }
}

export default useRegisters
