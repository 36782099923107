/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'

import {
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  SendOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons'
import { Empty, Modal, Tag, Form, Dropdown } from 'antd'
import dayjs from 'dayjs'

import Images from '~/assets/images'
import Table from '~/components/organisms/Table'
import { useAuth } from '~/contexts/auth'
import useCustomersAccounts from '~/hooks/useCustomersAccounts'

import FormAccount from './components/Detail'
import { permissionsLabels } from './components/Detail/permissions'
import Header from './components/Header'

const { confirm } = Modal

export default function Accounts() {
  const [form] = Form.useForm()

  const { data } = useAuth()

  const {
    onCreate,
    onRemove,
    onUpdate,
    countData,
    onResendEmail,
    customersAccounts,
    getCustomersAccounts,
    loadingCustomersAccounts,
  } = useCustomersAccounts(data.clientId)

  const [open, setOpen] = useState({ visible: false, type: null })
  const [account, setAccount] = useState({})
  const [pagination, setPagination] = useState({
    total: 0,
    pageSize: 20,
    current: 1,
  })
  const [filters, setFilters] = useState({ limit: 20, offset: 0, search: '' })

  function handleSearch(value) {
    if (value.length > 3) {
      setFilters({ ...filters, search: value })
      setTimeout(
        () => getCustomersAccounts({ ...filters, search: value }),
        1000
      )
    }

    setFilters({ ...filters, search: value })
    return getCustomersAccounts({ ...filters, search: value })
  }

  function onChangePage(page) {
    const pageLimit = page.current * filters.limit
    const currentOffset = page.current === 1 ? 0 : pageLimit - filters.limit

    setFilters((prev) => ({ ...prev, offset: currentOffset }))
    setPagination((prev) => ({ ...prev, current: page.current }))

    return getCustomersAccounts({
      ...filters,
      offset: currentOffset,
      limit: filters.limit,
    })
  }

  function handleSubmit(values) {
    if (open.type === 'update') {
      return onUpdate({
        id: account.id,
        ...values,
        permissions: { permissions: values.permissions },
      })
    }

    return onCreate({
      ...values,
      permissions: {
        permissions: values.permissions,
      },
    })
  }

  function onConfirmRemove(item) {
    return confirm({
      title: 'Alerta',
      icon: <ExclamationCircleOutlined />,
      content: 'Você tem certeza que deseja remover essa conta?',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        setTimeout(() => onRemove(item.id), 1000)
      },
    })
  }

  function handleDetail(item) {
    form.setFieldsValue({
      ...item,
      permissions: item.permissions !== null && item.permissions?.permissions,
    })
    setAccount((prevState) => ({
      ...prevState,
      ...item,
    }))
    return setOpen({ visible: true, type: 'update' })
  }

  function openForm() {
    form.resetFields(['username', 'email', 'confirm'])
    setAccount({})
    return setOpen({ visible: true, type: 'create' })
  }

  function handleMenuClick(key, item) {
    const push = {
      1: () => handleDetail(item),
      2: () => onResendEmail(item),
      3: () => onConfirmRemove(item),
    }

    return push[key]()
  }

  const menuItems = (item) => ({
    onClick: ({ key }) => handleMenuClick(key, item),
    items: [
      {
        key: 1,
        icon: <EditOutlined />,
        label: 'Editar',
      },
      !item.is_secure && {
        key: 2,
        icon: <SendOutlined />,
        label: 'Reenviar Convite',
        is_available: false,
      },
      {
        key: 3,
        icon: <UserDeleteOutlined />,
        label: 'Desativar Conta',
      },
    ],
  })

  useEffect(() => {
    getCustomersAccounts(filters)
  }, []) //eslint-disable-line

  return (
    <>
      <Header handleSearch={handleSearch} openForm={openForm} />

      <Table
        data={customersAccounts}
        loading={loadingCustomersAccounts}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Nome',
            dataIndex: 'username',
          },
          {
            title: 'E-mail',
            dataIndex: 'email',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (item) => (
              <Tag color={!item ? 'yellow' : 'green'}>
                {!item ? 'Pendente' : 'Ativo'}
              </Tag>
            ),
          },
          {
            title: 'Permissões',
            dataIndex: 'permissions',
            render: (item) =>
              item !== null
                ? item.permissions.map((i) => <Tag>{permissionsLabels[i]}</Tag>)
                : '',
          },
          {
            title: 'Criado Em',
            dataIndex: 'created_at',
            render: (item) => dayjs(item).format('DD/MM/YYYY HH:mm'),
          },
          {
            title: 'Atualizado Em',
            dataIndex: 'updated_at',
            render: (item) =>
              item ? dayjs(item).format('DD/MM/YYYY HH:mm') : '',
          },
          {
            render: (item) => (
              <Dropdown.Button
                menu={menuItems(item)}
                size="small"
                placement="bottomCenter"
                icon={<MoreOutlined />}
                buttonsRender={([leftButton, rightButton]) => [
                  leftButton,
                  React.cloneElement(rightButton),
                ]}
              >
                Ações
              </Dropdown.Button>
            ),
          },
        ]}
        total={Number(countData)}
        current={pagination.current}
        pageSize={pagination.pageSize}
        onChangePageSize={onChangePage}
      />

      {!loadingCustomersAccounts && customersAccounts.length === 0 && (
        <Empty
          image={Images.Profile}
          description="Nenhuma conta até o momento..."
        />
      )}

      <FormAccount
        form={form}
        item={account}
        open={open.visible}
        handleSubmit={handleSubmit}
        loading={loadingCustomersAccounts}
        close={() => setOpen({ ...open, visible: false })}
      />
    </>
  )
}
