/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

import { Space, Col, Row, Input, Select, Button } from 'antd'
import t from 'prop-types'

import { TYPES } from '../../helpers/options'
import SelectItem from '../SelectItem'
import styles from './styles'

const { Option } = Select

const CreateItem = ({
  forms,
  submit,
  loading,
  onChange,
  decrementItem,
  incrementItem,
  questionsTypes,
}) => (
  <Space direction="vertical" style={styles.widthContainer}>
    <Row gutter={[16, 16]}>
      <Col xs={16} sm={16} md={8} lg={8} xl={8}>
        <label>Seção</label>
        <Input
          value={forms.section}
          type="number"
          placeholder="Ex: 1"
          onChange={({ target }) => onChange('section', target.value)}
        />
      </Col>
      <Col xs={16} sm={16} md={8} lg={8} xl={8}>
        <label>Limite de Caracteres</label>
        <Input
          value={forms.length}
          type="number"
          placeholder="Ex: 100"
          onChange={({ target }) => onChange('length', target.value)}
        />
      </Col>
      <Col xs={16} sm={16} md={8} lg={8} xl={8}>
        <label>Tipo de Pergunta</label>
        <Select
          value={forms.type}
          optionFilterProp="children"
          style={styles.widthContainer}
          placeholder="Tipo de Pergunta"
          onChange={(value) => onChange('type', value)}
        >
          {questionsTypes.map((i, index) => (
            <Option value={i.value} key={Number(index)}>
              {i.text}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={24}>
        <Input
          value={forms.question}
          type="text"
          placeholder="Pergunta"
          onChange={({ target }) => onChange('question', target.value)}
        />
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={24}>
        <Input
          value={forms.question_key}
          type="text"
          placeholder="Chave BI"
          onChange={({ target }) => onChange('question_key', target.value)}
        />
      </Col>
    </Row>
    {[TYPES.MULTIPLE, TYPES.SELECT_LIST, TYPES.RATING].includes(forms.type) && (
      <Space direction="horizontal" size="middle">
        <SelectItem
          hasScoreRating={forms.type === TYPES.RATING}
          items={forms.items}
          removeItem={decrementItem}
          onChangeSelectItem={(item) => incrementItem(item)}
        />
      </Space>
    )}

    <Button
      shape="round"
      type="primary"
      loading={loading}
      disabled={loading}
      onClick={() => submit()}
    >
      Criar
    </Button>
  </Space>
)

CreateItem.defaultTypes = {
  loading: false,
}

CreateItem.propTypes = {
  forms: t.shape({
    example: t.string,
    items: t.arrayOf(t.string),
    length: t.number,
    operator_warning: t.string,
    question: t.string,
    section: t.number,
    type: t.number,
  }),
  submit: t.func,
  loading: t.bool,
  onChange: t.func,
  decrementItem: t.func,
  incrementItem: t.func,
  questionsTypes: t.arrayOf(t.objectOf(t.string)),
}

export default CreateItem
